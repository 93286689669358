
import { mapState, mapMutations, mapActions } from 'vuex'

import Header from '@/components/globals/Header'
import Footer from '@/components/globals/Footer'
import SiteOverlay from '@/components/globals/SiteOverlay'
import LocalSitePopup from '@/components/layout/LocalSitePopup'

export default {
  name: 'CommonLayoutContainer',
  components: {
    Header,
    Footer,
    LocalSitePopup,
    SiteOverlay,
  },
  props: {
    webview: Boolean,
    mainMenu: Array,
    mobileMenu: Array,
    footerMenu: Object,
    logos: Object,
    locationIcon: Object,
    chevron: Object,
    rewardsTrophy: Object,
  },
  head() {
    return {
      script: [
        {
          src: "https://a42cdn.usablenet.com/a42/homewardkitchen/default/prod/accessibilitystatement.js",
          body: true
        },
      ],
    }
  },
  async mounted() {
    // clean up
    this.setLocation(null)
    this.setMenu(null)
    await this.abandonCart()

    const content = {
      // image_url: this.img,
      // heading: 'Dear Valued Guests',
      image_url: 'https://a.storyblok.com/f/262683/1080x1920/857a27613d/gc-drivethruboard-comingsoon.png',
//       body: `
// <p>With heartfelt gratitude, we announce that Homeward Kitchen in Southern Pines has closed its doors. Serving this community has been a true joy, and we are incredibly thankful for your support over the past year. Getting to know you, welcoming you in, and sharing our love for great food and warm hospitality has meant the world to us.</p>
// <p>While this chapter comes to a close, we are excited for what's ahead! This location will soon transform into <strong>Golden Corral Favorites</strong>, a brand-new dining experience opening in <strong>April 2025</strong>. Many of our dedicated team members, including our General Manager, will be staying on to continue serving you with the same hospitality you've come to love.</p>
// <p>Thank you for being a part of our journey—we can’t wait to welcome you back soon!</p>
// <p>With appreciation,<br>
// <strong>The Homeward Kitchen Team</strong></p>`,
      // buttons: [
      //   {
      //     title: 'Ok',
      //     class: 'primary btn large',
      //     callback: () => {
      //       this.closePopup()
      //     },
      //   },
      // ],
    }

    setTimeout(() => {
      this.setSitePopupInfo(content)
      this.setShowSitePopup(true)
      document.body.style.overflow = 'hidden'
    }, 300)
  },
  computed: {
    ...mapState([ 'appInfo', 'location', 'loading', 'errors', 'token', 'user', 'cart' ]),
    ...mapState({
      rewards: state => state.members.rewards,
    }),
    isMobile() {
      return this.$mq == 'sm' || this.$mq == 'md' ? true : false
    },
    pagePath() {
      return this.$route.path.replace(/\//g, '_').replace(/_$/, '')
    },
    hasErrors() {
      return this.errors?.length > 0 
    },
    webViewClass() {
      const pagePath = this.pagePath ? `page_${this.pagePath}` : '';
      return `${this.webview ? 'app' : 'web'}-layout ${pagePath}`
    },
    config() {
      return {
        headerInfo: {
          title: 'Your Cart',
          leftAction: {
            show: true,
            text: '',
            iconName: 'arrow-left-icon',
            iconClasses: '',
            callback: () => {
              this.$nuxt.$router.push('/menu/9982')
              this.hideCart()
            }
          },
          rightAction: {
            show: true,
            text: this.isMobile ? 'Change' :'Change Location',
            iconName: 'location-icon',
            iconClasses: '',
            callback: () => {
              this.$nuxt.$router.push('/locations')
              this.hideCart()
            }
          },
        },
        cartTitle: {
          title: 'Your Order',
          leftAction: {
            show: true,
            iconName: 'order-icon',
            iconClasses: '',
          },
          rightAction: {
            show: true,
            text: 'Clear All Items',
            iconName: 'trash-icon',
            iconClasses: '',
            callback: async () => {
              await this.abandonCart()
              this.hideCart()
            }
          },
        }
      }
    }
  },
  methods: {
    ...mapMutations([ 'setLocation', 'setMenu', 'setShowCart' ]),
    ...mapMutations({
      setShowSitePopup: 'setShowSitePopup',
      setSitePopupInfo: 'setSitePopupInfo',
    }),
    ...mapActions([ 'getUser', 'getCart', 'abandonCart', 'reloadLocation' ]),
    ...mapActions({
      getRewards: 'members/getRewards',
    }),
    hideCart() {
      document.body.style.overflow = 'auto'
      this.setShowCart(false)
    },
    closePopup() {
      this.setSitePopupInfo(null)
      this.setShowSitePopup(false)
      document.body.style.overflow = 'auto'
    },
  },
  async beforeMount() {
    if (this.$route && this.$route.query && this.$route.query.store) {
      if (this.$route.query.store === 'clear') {
        this.setLocation(null)
        this.setMenu(null)
        await this.abandonCart()
      }
    }

    if (this.token && !this.user) {
      await this.getUser()
    }
    if (this.appInfo.checkout.upsells !== false && this.user && this.rewards.length === 0) {
      await this.getRewards()
    }
    if (this.cart) {
      await this.getCart()
    }

    // Reload the location data - mainly the open/closed status
    if (this.location || this.cart) {
      const menuId = this.cart && this.cart.menu_id ? this.cart.menu_id : this.location.menu_id
      await this.reloadLocation(menuId)

      // Transfer cart if the menus are still different for some reason
      if (this.cart && this.location && this.cart.menu_id !== this.location.menu_id) {
        this.transferCart(menuId)
      }
    }
  },
}
