
import { mapState, mapMutations } from 'vuex'

import Icon from '@pleinair/bigcolony-vue/components/common/Icon/index.vue'

export default {
  name: 'LocalSitePopup',
  components: {
    Icon,
  },
  computed: {
    ...mapState([ 'showSitePopup', 'sitePopupInfo' ]),
    blok() {
      return this.sitePopupInfo
    },
    img() {
      if (this.blok.image_url) {
        return this.blok.image_url
      }
      return ''
    }
  },
  methods: {
    ...mapMutations([ 'setShowSitePopup', 'setSitePopupInfo'])
  }
}
